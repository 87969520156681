const LOCAL_STORAGE_KEY = 'driveX_baseApiUrl';

export const composeApiUrl = (url:string):string => `${getApiBaseUrl()}${url}`;

export const getApiBaseUrl = () => {
  const baseApiUrl = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (baseApiUrl?.length && baseApiUrl.length > 10) return baseApiUrl;

  return initializeBaseUrl();
};

const initializeBaseUrl = () => {
  if (process.env.REACT_APP_USE_CUSTOM_URL !== 'true') return process.env.REACT_APP_API_URL;

  const customUrls = [
    // youreit.eu custom URL
    new CustomUrls(process.env.REACT_APP_YOUREIT_API_URL, process.env.REACT_APP_YOUREIT_FRONTEND_URL)
  ];

  const validCustomUrls = customUrls.filter((customUrl) => customUrl.isConfigured());
  for (const customUrl of validCustomUrls) {
    // If SmartScan is served from a known custom URL, use a custom API URL as well
    if (window.location.href.indexOf(customUrl.frontEndUrl as string) > -1) {
      localStorage.setItem(LOCAL_STORAGE_KEY, customUrl.apiUrl as string);
      return localStorage.getItem(LOCAL_STORAGE_KEY);
    }
  }

  localStorage.setItem(LOCAL_STORAGE_KEY, process.env.REACT_APP_API_URL as string);
  return localStorage.getItem(LOCAL_STORAGE_KEY);
};

class CustomUrls {
  constructor(apiUrl: string | undefined, frontEndUrl: string | undefined) {
    this.apiUrl = apiUrl;
    this.frontEndUrl = frontEndUrl;
  }

  public apiUrl: string | undefined;

  public frontEndUrl: string | undefined;

  isConfigured = () => (this.apiUrl as string).startsWith('http') &&
    (this.frontEndUrl as string).startsWith('http');
}
