const types = {
  STORE_CUSTOMER_CODE_SUCCESS: '@drivex/session/STORE_CUSTOMER_CODE_SUCCESS',
  STORE_PREFERRED_LANGUAGE_SUCCESS: '@drivex/session/STORE_PREFERRED_LANGUAGE_SUCCESS',
  STORE_CURRENT_USER: '@drivex/session/STORE_CURRENT_USER',
  RENEW_CURRENT_USER: '@drivex/session/RENEW_CURRENT_USER',
  LOG_OUT_STARTED: '@drivex/session/LOG_OUT_STARTED',
  LOG_OUT_SUCCESS: '@drivex/session/LOG_OUT_SUCCESS',
  LOG_OUT_FAILURE: '@drivex/session/LOG_OUT_FAILURE',
  STORE_UNSUPPORTED_BROWSER: '@drivex/session/UNSUPPORTED_BROWSER',
  STORE_USE_NATIVE_CAMERA: '@drivex/session/STORE_USE_NATIVE_CAMERA',
  TERMS_OF_SERVICE_ACCEPTED: '@drivex/session/TERMS_OF_SERVICE_ACCEPTED',
  SET_PUBLIC_USER_TRACKING_INFO: '@drivex/session/SET_PUBLIC_USER_TRACKING_INFO'
};

export { types };
