import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPublicUserTrackingInfo } from 'redux/root.selectors';
import { __dev__, __prod__, parseUrlId } from 'utils';
import queryString from 'querystring';

declare global {
  interface Window {
    SessionStack?: any;
    SessionStackKey?: string;
  }
}

type SessionStackProps = {
  loadSessionStack: boolean;
};

const SessionStack = ({ loadSessionStack }: SessionStackProps) => {
  const [sessionStackLoaded, setSessionStackLoaded] = useState(false);
  const publicUserTrackingInfo = useSelector(selectPublicUserTrackingInfo);

  const query = queryString.parse(window.location.search.substr(1));
  const { urlId } = query;

  useEffect(() => {
    if (!parseUrlId(urlId)) return;
    if (sessionStackLoaded && publicUserTrackingInfo) {
      const data = {
        // Identify each AccessUrl.Token as a separate user in SessionStack
        // This way all sessions made with the same URL will be grouped as one user
        userId: parseUrlId(urlId),
        displayName: publicUserTrackingInfo.photoSeries.vehicle.vin || '',
        email: publicUserTrackingInfo.email || '',
        app_str: `DriveX SmartScan${__dev__ ? ' [DEV]' : ''}`,
        customerCode_str: publicUserTrackingInfo.customerCode || '',
        customerName_str: publicUserTrackingInfo.customerName || '',
        returnUrl_str: publicUserTrackingInfo.returnUrl || '',
        vin_str: publicUserTrackingInfo.photoSeries.vehicle.vin || '',
        registrationNo_str: publicUserTrackingInfo.photoSeries.vehicle.registrationNo || '',
        photoSeriesId_str: publicUserTrackingInfo.photoSeries.id || '',
        isLive_bool: __prod__
      };

      window.SessionStack.identify(data);
    }
  }, [sessionStackLoaded, publicUserTrackingInfo, urlId]);

  useEffect(() => {
    const handleSessionStackCleanup = () => {
      if (window.SessionStack && typeof window.SessionStack.stop === 'function') {
        window.SessionStack.stop();
      }
    };

    if (
      process.env.REACT_APP_SESSIONSTACK_KEY &&
      process.env.REACT_APP_ENABLE_SESSIONSTACK === 'true' &&
      loadSessionStack
    ) {
      // Remove any existing script before adding a new one
      const existingScript = document.getElementById('sessionstack-script');
      if (existingScript) {
        existingScript.remove();
      }

      let windowContext: any = window;
      let SessionStackKey = 'SessionStack';
      let apiKey = process.env.REACT_APP_SESSIONSTACK_KEY;

      // Initialize the SessionStack object if not already initialized
      windowContext.SessionStackKey = SessionStackKey;
      windowContext[SessionStackKey] = windowContext[SessionStackKey] || {
        t: apiKey,
        q: []
      };

      // Methods to be used with SessionStack (like start, stop, identify, etc.)
      let methods = [
        'start',
        'stop',
        'identify',
        'getSessionId',
        'log',
        'setOnDataCallback',
        'trackEvent'
      ];

      // Initialize each method to push it to the queue if not yet implemented
      for (let i = 0; i < methods.length; i++) {
        let method = methods[i];
        // eslint-disable-next-line func-names
        windowContext[SessionStackKey][method] = windowContext[SessionStackKey][method] || function (...args: any[]) {
          windowContext[SessionStackKey].q.push([method].concat([].slice.call(args, 0)));
        };
      }

      // Create the script element to load the SessionStack script
      let scriptElement = document.createElement('script');

      scriptElement.id = 'sessionstack-script';
      scriptElement.async = true;
      scriptElement.crossOrigin = 'anonymous';
      scriptElement.src = 'https://cdn.sessionstack.com/sessionstack.js';
      scriptElement.onload = () => {
        setSessionStackLoaded(true);
      };

      // Insert the script into the DOM before the first script tag
      let firstScriptTag = document.getElementsByTagName('script')[0];

      if (firstScriptTag.parentNode) {
        firstScriptTag.parentNode.insertBefore(scriptElement, firstScriptTag);
      }

      // Add the event listener to stop SessionStack before the page is unloaded or refreshed
      window.addEventListener('beforeunload', handleSessionStackCleanup);
    }

    return () => {
      window.removeEventListener('beforeunload', handleSessionStackCleanup);

      // Cleanup script if needed
      const existingScript = document.getElementById('sessionstack-script');
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [loadSessionStack]);

  return null;
};

export default SessionStack;
