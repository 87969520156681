/* eslint-disable no-unused-vars */
import { ActionType } from 'typesafe-actions';

import { GENERIC_STATE_KEYS, IGenericState } from '../root';

export interface IPublicUserAuthorizationDto extends IAuthorizationDto {
  returnUrl?: string;
  customerName: string;
}

export interface IAuthorizationDto {
  id: string;
  email: string;
  customerCode: string;
  mustChangePassword: boolean;
  permissions: number[];
  preferredLang: string;
  sessionExpiresAt: string;
  askAdditionalInformation: boolean;
  token?: string;
  refreshToken?: string;
}

export interface ISessionState extends IGenericState {
  preferredLang: string;
  customerCode: string;
  currentUser: IPublicUserAuthorizationDto | null;
  unsupportedBrowser: boolean;
  useNativeCamera: boolean;
  termsOfServiceAccepted: boolean;
  publicUserTrackingInfo: IPublicUserTrackingInfo | null
}

export type IPublicUserTrackingInfo = {
  // Id of the currently logged in user
  id: string,
  email: string,
  customerCode: string,
  customerName: string,
  publicUserPhotoSeriesRecipient: string,
  returnUrl: string,
  photoSeries: { id: string, vehicle: { registrationNo: string, vin: string } }
  sessionExpiresAt: string,
};

export type TSessionActions = ActionType<typeof import('./session.actions')>;

const SESSION_NAME = 'session';

const SESSION_STATE_KEYS = {
  ...GENERIC_STATE_KEYS,
  preferredLang: 'preferredLang',
  customerCode: 'customerCode',
  currentUser: 'currentUser',
  publicUserSessionData: 'publicUserSessionData',
  unsupportedBrowser: 'unsupportedBrowser',
  useNativeCamera: 'useNativeCamera',
  termsOfServiceAccepted: 'termsOfServiceAccepted',
  publicUserTrackingInfo: 'publicUserTrackingInfo'
};

 enum PERMISSION_TYPES {
  VIEW_IMAGES= 0,
  CREATE_IMAGES= 1,
  CREATE_PHOTO_SERIES= 2,
  VIEW_VEHICLES= 3,
  VIEW_PHOTO_SERIES= 4,
  EDIT_CUSTOMER_CONFIGURATION= 5,
}

export {
  SESSION_NAME, SESSION_STATE_KEYS, PERMISSION_TYPES
};
