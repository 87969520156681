/* eslint-disable no-unused-vars */
import { AjaxError } from 'rxjs/ajax';
import { action } from 'typesafe-actions';
import { Action, ActionCreator } from 'redux';
import { types } from './session.types';
import { IAuthorizationDto, IPublicUserAuthorizationDto, IPublicUserTrackingInfo } from './session';
import { Paths } from 'routes';
import { makePost } from 'services';

const storeCustomerCode: ActionCreator<Action<string>> = (customerCode: string) => action(types.STORE_CUSTOMER_CODE_SUCCESS, customerCode);
const storeCurrentUser: ActionCreator<Action<string>> = (currentUser: IPublicUserAuthorizationDto) => action(types.STORE_CURRENT_USER, currentUser);
const renewCurrentUser: ActionCreator<Action<string>> = (currentUser: IAuthorizationDto) => action(types.RENEW_CURRENT_USER, currentUser);
const storeUnsupportedBrowser: ActionCreator<Action<string>> = (unsupportedBrowser: boolean) =>
  action(types.STORE_UNSUPPORTED_BROWSER, unsupportedBrowser);
const storeUseNativeCamera: ActionCreator<Action<string>> = (useNativeCamera: boolean) =>
  action(types.STORE_USE_NATIVE_CAMERA, useNativeCamera);

const logoutSuccessful: ActionCreator<Action> = () => action(types.LOG_OUT_SUCCESS);

const logoutFailed: ActionCreator<Action<string>> = (payload: string) => action(types.LOG_OUT_FAILURE, payload);

const logout = (onSuccessDispatch?: Function, onErrorDispatch?: Function) => (dispatch: (arg0: Action<any>) => void) => {
  dispatch(action(types.LOG_OUT_STARTED));

  makePost('session/logout', {}).subscribe(
    () => {
      if (onSuccessDispatch) onSuccessDispatch();
      window.location.replace(Paths.home);
      dispatch(logoutSuccessful());
    },
    ({ response: { error } }: AjaxError) => {
      if (onErrorDispatch) onErrorDispatch();
      dispatch(logoutFailed(error as string));
    }
  );
};

const termsOfServiceAccepted: ActionCreator<Action> = () => action(types.TERMS_OF_SERVICE_ACCEPTED);
const setPublicUserTrackingInfo: ActionCreator<Action<string>> = (publicUserTrackingInfo: IPublicUserTrackingInfo) =>
  action(types.SET_PUBLIC_USER_TRACKING_INFO, publicUserTrackingInfo);

export {
  storeCustomerCode, storeCurrentUser, logout, renewCurrentUser,
  storeUnsupportedBrowser, storeUseNativeCamera, termsOfServiceAccepted,
  setPublicUserTrackingInfo
};
