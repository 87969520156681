import { createSelector } from 'reselect';

import { TRootState } from '../root';
import { SESSION_NAME, SESSION_STATE_KEYS } from './session';

const selectSessionState = (state: TRootState | any) => state[SESSION_NAME];
const selectSessionIsLoading = createSelector(selectSessionState, (session) => session[SESSION_STATE_KEYS.isLoading]);
const selectSessionError = createSelector(selectSessionState, (session) => session[SESSION_STATE_KEYS.error]);
const selectCustomerCode = createSelector(selectSessionState, (session) => session[SESSION_STATE_KEYS.customerCode]);
const selectPreferredLang = createSelector(selectSessionState, (session) => session[SESSION_STATE_KEYS.preferredLang]);
const selectCurrentUser = createSelector(selectSessionState, (session) => session[SESSION_STATE_KEYS.currentUser]);
const selectUnsupportedBrowser = createSelector(selectSessionState, (session) => session[SESSION_STATE_KEYS.unsupportedBrowser]);
const selectUseNativeCamera = createSelector(selectSessionState, (session) => session[SESSION_STATE_KEYS.useNativeCamera]);
const selectTermsOfServiceAccepted = createSelector(selectSessionState, (session) => session[SESSION_STATE_KEYS.termsOfServiceAccepted]);
const selectPublicUserTrackingInfo = createSelector(selectSessionState, (session) => session[SESSION_STATE_KEYS.publicUserTrackingInfo]);

export {
  selectSessionIsLoading, selectSessionError, selectCustomerCode, selectPreferredLang,
  selectCurrentUser, selectUnsupportedBrowser, selectUseNativeCamera, selectTermsOfServiceAccepted,
  selectPublicUserTrackingInfo
};
