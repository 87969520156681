/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from 'react';
import { IDamage, DamageType, ICustomDamageType, BodyPart } from 'redux/damages/damages';
import styled from 'styled-components';
import { Button, SkeletonUi } from 'components/partials';
import { useIsScreenPortrait, useTheme } from 'hooks';
import { Translate } from 'react-localize-redux';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { ReactComponent as FirstCaptureInstruction } from 'assets/svgs/manual-labelling/instr-first-capture.svg';
import { ReactComponent as SecondCaptureInstruction } from 'assets/svgs/manual-labelling/instr-second-capture.svg';
import { ReactComponent as ChipCaptureInstruction } from 'assets/svgs/manual-labelling/instr-chip-capture.svg';
import { ReactComponent as Bulb } from 'assets/svgs/icn-bulb.svg';
import { getThumbnailUrl } from 'services/thumbnail';
import { updateAdditionalImagesTaken } from 'redux/root.actions';
import { useDispatch } from 'react-redux';
import { SubTypeConfiguration, ADDITIONAL_SUB_TYPES } from 'redux/workflows/workflows';

const { damage_labelling: {
  damage_type,
  capture_damage_title,
  damage_type_text,
  capture_damage_description,
  body_part,
  body_part_text,
  capture_chip_damage_text
},
instructions: {
  skip
} } = TRANSLATIONS_VALUES_KEYS;

// Height is not inherited from parent and I have no idea why.
// That is the reason I am using min-heigh: 76vh, instead of 100%
const Wrapper = styled.div<{isPortrait: boolean}>`
  display: flex;
  flex-direction: column;
  min-height: ${({ isPortrait }) => isPortrait ? '76vw' : '76vh'};
`;

const Row = styled.div<{ flex?: number }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${({ flex }) => flex ? `flex: ${flex};` : ''}
`;

const InformationWrapper = styled.div`
  padding-top: 3.188rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Column = styled.div<{ flex: number }>`
  flex: ${(props) => `${props.flex}%`};
`;

const Title = styled.div`
  font-size: 1.563rem;
  font-weight: bold;
`;

const ThumbnailWrapper = styled.div`
  height: 100%
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
`;

const ThumbnailImage = styled.img`
  border-radius: 10%;
  width: 13.125rem;
`;

const DamageInformation = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
`;

const FirstCapture = styled(FirstCaptureInstruction)`
  width: 6.5rem;
  position: absolute;
  right: 8.5rem;
  bottom: -1rem;
`;

const SecondCapture = styled(SecondCaptureInstruction)`
  width: 6rem;
  position: absolute;
  right: -2rem;
  bottom: -1rem;
`;

const ChipCapture = styled(ChipCaptureInstruction)`
  width: 10rem;
  position: absolute;
  right: -2.5rem;
  bottom: -3rem;
`;

interface MarkedDamagesPropTypes {
  damage: IDamage,
  imageSubTypes: SubTypeConfiguration[],
  customDamageTypes: ICustomDamageType[]
}

const MarkedDamages: React.FC<MarkedDamagesPropTypes> = ({ customDamageTypes, damage, imageSubTypes }) => {
  const dispatch = useDispatch();
  const { styles } = useTheme();
  const { id, imageFileName } = damage;

  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const objectUrlRef = useRef<string | null>(null);

  const thumbnailUrl = getThumbnailUrl(imageFileName, id);
  const isPortrait = useIsScreenPortrait();
  const firstImageCapture = damage.additionalImagesTaken === undefined ||
                            damage.additionalImagesTaken !== 1;

  const subTypeConfiguration = firstImageCapture
    ? imageSubTypes.find((cfg) => cfg.imageSubType === ADDITIONAL_SUB_TYPES.FIRST_CLOSE_UP)
    : imageSubTypes.find((cfg) => cfg.imageSubType === ADDITIONAL_SUB_TYPES.SECOND_CLOSE_UP);

  const hasSecondCloseUp = imageSubTypes.some((cfg) => cfg.imageSubType === ADDITIONAL_SUB_TYPES.SECOND_CLOSE_UP);
  const isChipCapture = damage.damageType === DamageType.Chip;

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const cleanImageSrc = () => {
      if (objectUrlRef.current) {
        URL.revokeObjectURL(objectUrlRef.current);
        objectUrlRef.current = null;
      }
    };

    const downloadThumbnail = async (retry = 3) => {
      setLoading(true);
      try {
        const response = await fetch(thumbnailUrl, { signal: controller.signal });
        if (!response.ok) throw new Error();

        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);

        if (isMounted) {
          cleanImageSrc(); // Revoke previous URL
          objectUrlRef.current = objectUrl;
          setImageSrc(objectUrl);
          setLoading(false);
        }
      } catch (error) {
        if (retry > 0 && isMounted) {
          setTimeout(() => downloadThumbnail(retry - 1), 5000);
        }
      }
    };

    cleanImageSrc();
    downloadThumbnail();

    return () => {
      isMounted = false;
      controller.abort();
      cleanImageSrc();
    };
  }, [thumbnailUrl]);

  return (
    <Translate>
      {({ translate }) => {
        let damageType;

        if (!damage.customDamageTypeId) {
          Object.keys(DamageType).forEach((key: string) => {
            if (DamageType[key as keyof typeof DamageType] === damage.damageType) {
              damageType = translate(damage_type[key.toLowerCase() as keyof typeof damage_type]);
            }
          });
        } else {
          damageType = customDamageTypes.find((c) => c.id === damage.customDamageTypeId)?.name;
        }

        return (
          <Wrapper isPortrait={isPortrait}>
            <Row>
              <Title data-testid="title">{translate(capture_damage_title)}</Title>
            </Row>
            <Row flex={1}>
              <Column flex={40}>
                <InformationWrapper>
                  {
                    !isChipCapture && (
                      <div>
                        <span style={{ fontSize: '0.75rem' }} dangerouslySetInnerHTML={{ __html: translate(capture_damage_description) as string }} />
                      </div>
                    )
                  }
                  {
                    isChipCapture && (
                      <div style={{ display: 'flex', alignItems: 'center', padding: '0.5rem', backgroundColor: '#D9EBFF', borderRadius: '0.5rem' }}>
                        <Bulb />
                        <span
                          style={{ fontSize: '0.75rem', marginLeft: '0.75rem', fontWeight: 600 }}
                        >
                          {translate(capture_chip_damage_text)}
                        </span>
                      </div>
                    )
                  }
                  <div>
                    <DamageInformation>
                      <span>{translate(damage_type_text)}: <b>{damageType}</b></span>
                      <span>
                        {translate(body_part_text)}: <b>{translate(body_part[BodyPart[damage.bodyPart].toLowerCase() as keyof typeof body_part])}</b>
                      </span>
                    </DamageInformation>
                    {
                      subTypeConfiguration?.optional && (
                        <Button
                          style={{ marginTop: '.75rem' }}
                          type="button"
                          testId="skip"
                          bg={styles.colorSecondaryButtonBackground}
                          color={styles.colorSecondaryButtonText}
                          hoverBorderColor={styles.colorSecondaryButtonBackgroundDark}
                          hoverShadowColor={styles.colorSecondaryButtonBackgroundLight}
                          onClick={() => dispatch(updateAdditionalImagesTaken())}
                        >
                          {translate(skip)}
                        </Button>
                      )
                    }
                  </div>
                </InformationWrapper>
              </Column>
              <Column flex={60}>
                <ThumbnailWrapper>
                  { loading && <SkeletonUi width="13.125rem" height="13.125rem" shape="radiusHorizone" noMargin /> }
                  { imageSrc && !loading && <ThumbnailImage alt="Damage thumbnail" src={imageSrc} /> }
                  { !isChipCapture && firstImageCapture && <FirstCapture /> }
                  { !isChipCapture && hasSecondCloseUp && !firstImageCapture && <SecondCapture /> }
                  { isChipCapture && <ChipCapture /> }
                </ThumbnailWrapper>
              </Column>
            </Row>
          </Wrapper>
        );
      }}
    </Translate>
  );
};

export default MarkedDamages;
