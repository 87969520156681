import { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';
import { useTheme } from 'hooks';
import { Logo } from 'assets';
import { LandscapeLock } from 'components/containers';
import { Button } from 'components/partials';
import {
  selectLocalLogoUrl,
  selectSelectedWorkflow,
  selectPublicUserTrackingInfo,
  getPhotoSeries
} from 'redux/root.selectors';
import {
  setPublicUserTrackingInfo
} from 'redux/root.actions';
import { IWorkflow } from 'redux/workflows/workflows';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { makePatch } from 'services';

import { IPhotoSeries } from 'redux/photoseries/photoseries';
import {
  LandingPageWrapper,
  LandingPageWrapperCol1,
  LandingPageWrapperCol2,
  Title,
  SubTitle
} from '../landing/landing.component';

const { photoseries, vehicle_scan } = TRANSLATIONS_VALUES_KEYS;

const LicensePlateRow = styled.div<{ color: string }>`
  font-size: 1rem;
  display: flex;
  font-size: 0.8125rem;
  padding-bottom: 1rem;
  flex-direction: column;
  justify-content: end;

  h2 {
    font-size: 0.813rem;
    text-align: center;
  }

  input {
    height: 3.375rem;
    border: 1px solid #E2E2E2;
    border-radius: 0.625rem;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.625rem !important;
    font-weight: 700;
    color: ${({ color }) => color};
  }
`;

function LicensePlate() {
  const [licensePlate, setLicensePlate] = useState<string>('');
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const history = useHistory();
  const localLogo: string = useSelector(selectLocalLogoUrl);
  const {
    styles: {
      colorHeading
    },
  } = useTheme();
  const dispatch = useDispatch();

  const publicUserTrackingInfo = useSelector(selectPublicUserTrackingInfo);
  const selectedWorkflow: IWorkflow = useSelector(selectSelectedWorkflow);
  const photoSeries: IPhotoSeries = useSelector(getPhotoSeries);

  const onClickHandler = () => {
    setIsRedirecting(true);

    makePatch(`photoSeries/${photoSeries.id}`, {
      registrationNo: licensePlate
    }).subscribe(() => {
      // updating tracking information
      const newPublicUserTrackingInfo = {
        ...publicUserTrackingInfo,
      };

      newPublicUserTrackingInfo.photoSeries.vehicle.registrationNo = licensePlate;
      dispatch(setPublicUserTrackingInfo(newPublicUserTrackingInfo));

      history.push(`/capture/vehicle-type/${selectedWorkflow.vehicleType}`);
    });
  };

  return (
    <LandscapeLock>
      <LandingPageWrapper className="wrapper">
        <LandingPageWrapperCol1 isLocalLogo={!!localLogo}>
          <Logo />
        </LandingPageWrapperCol1>

        <LandingPageWrapperCol2>
          <Title data-testid="landing-title">
            <Translate id={photoseries.welcome_heading_text} />
          </Title>
          <SubTitle>
            <Translate id={photoseries.license_plate_subheading_text} />
          </SubTitle>
          <LicensePlateRow color={colorHeading}>
            <h2>
              <Translate id={photoseries.insert_license_plate_text} />
            </h2>
            <input
              type="text"
              spellCheck="false"
              onChange={(e) => {
                setLicensePlate(e.target.value.trim());
              }}
              value={licensePlate}
              disabled={isRedirecting}
            />
          </LicensePlateRow>
          <Button
            testId="continuePSCaptureBtn"
            onClick={onClickHandler}
            disabled={licensePlate.length < 2 || isRedirecting}
            animate
          >
            <Translate id={vehicle_scan.continue_btn} />
          </Button>
        </LandingPageWrapperCol2>
      </LandingPageWrapper>
    </LandscapeLock>
  );
}

export default LicensePlate;
