import { createSelector } from 'reselect';

import { TRootState } from '../root';
import { DAMAGES_NAME, IDamagesState } from './damages';
import { findInProgressDamage, findCaptureProgress } from './damages.utils';
import { ImageTypeKeys, WINDSHIELD_SUB_TYPES } from 'redux/workflows/workflows';

const selectDamagesState = (state: TRootState | any) => state[DAMAGES_NAME] as IDamagesState;

const getImageId = (_: TRootState | any, imageId: string) => imageId;

const getDraftDamages = createSelector(
  selectDamagesState,
  getImageId,
  (damagesState, imageId) => damagesState.damages[imageId]?.filter((damage) => !damage.id),
);

const getImageSavedDamages = createSelector(
  selectDamagesState,
  getImageId,
  (damagesState, imageId) => damagesState.damages[imageId]?.filter((damage) => damage.id)
);

const getAdditionalImagesPerDamage = createSelector(
  selectDamagesState,
  (damagesState) => damagesState.additionalImagesPerDamage
);

const getInProgressDamageCapture = createSelector(
  selectDamagesState,
  (damagesState) => findInProgressDamage(damagesState)
);

const getDamageCaptureProgress = createSelector(
  selectDamagesState,
  (damagesState) => findCaptureProgress(damagesState, undefined, undefined)
);

const getWrrDamageCaptureProgress = createSelector(
  selectDamagesState,
  (damagesState) => findCaptureProgress(damagesState, ImageTypeKeys.Windshield, WINDSHIELD_SUB_TYPES.FROM_OUTSIDE)
);

const getCustomDamageTypes = createSelector(
  selectDamagesState,
  (damagesState) => damagesState.customDamageTypes
);

export {
  getDraftDamages, getImageSavedDamages,
  getAdditionalImagesPerDamage, getInProgressDamageCapture,
  getDamageCaptureProgress, getCustomDamageTypes, getWrrDamageCaptureProgress
};
