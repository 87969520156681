import { Reducer } from 'redux';
import { types } from './session.types';
import { IAuthorizationDto, IPublicUserAuthorizationDto, IPublicUserTrackingInfo, ISessionState } from './session';

const initialState: ISessionState = {
  isLoading: false,
  error: null,
  preferredLang: '',
  customerCode: '',
  currentUser: null,
  unsupportedBrowser: false,
  useNativeCamera: false,
  termsOfServiceAccepted: false,
  publicUserTrackingInfo: null
};

const sessionReducer: Reducer<ISessionState, any> = (state = initialState, action): ISessionState => {
  switch (action.type) {
    case types.SET_PUBLIC_USER_TRACKING_INFO: {
      return { ...state, publicUserTrackingInfo: action.payload as IPublicUserTrackingInfo };
    }
    case types.TERMS_OF_SERVICE_ACCEPTED: {
      return { ...state, termsOfServiceAccepted: true };
    }
    case types.STORE_CURRENT_USER: {
      const publicUserAuthorizationDto = action.payload as IPublicUserAuthorizationDto;

      delete publicUserAuthorizationDto.token;
      delete publicUserAuthorizationDto.refreshToken;

      return { ...state, currentUser: publicUserAuthorizationDto };
    }

    case types.RENEW_CURRENT_USER: {
      const authorizationDto = action.payload as IAuthorizationDto;

      delete authorizationDto.token;
      delete authorizationDto.refreshToken;

      const currentUser = {
        ...state.currentUser,
        ...authorizationDto
      } as IPublicUserAuthorizationDto;

      return { ...state, currentUser };
    }

    case types.STORE_CUSTOMER_CODE_SUCCESS: {
      return { ...state, customerCode: action.payload as string };
    }

    case types.STORE_PREFERRED_LANGUAGE_SUCCESS: {
      return { ...state, preferredLang: action.payload as string };
    }

    case types.LOG_OUT_STARTED: {
      return { ...state, isLoading: true };
    }

    case types.LOG_OUT_FAILURE: {
      return { ...state, isLoading: false, error: action.payload as string };
    }

    case types.LOG_OUT_SUCCESS: {
      return initialState;
    }

    case types.STORE_UNSUPPORTED_BROWSER: {
      return {
        ...state,
        unsupportedBrowser: action.payload as boolean
      };
    }

    case types.STORE_USE_NATIVE_CAMERA: {
      return {
        ...state,
        useNativeCamera: action.payload as boolean
      };
    }

    default: {
      return state;
    }
  }
};

export { sessionReducer };
